@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
